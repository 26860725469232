<template>
  <div class="custom-icon">    
    <div class="logo-container" v-tooltip.top="tooltipWithDefault">
      <div class="without-container">
        <div class="without-1"  v-if="showWithout">&nbsp;</div>
        <div class="almostWithout-1"  v-if="showAlmostWithout">&nbsp;</div>
      </div>
      <div class="without-container" style="display: none;">
        <div class="without-2"  v-if="showWithout">&nbsp;</div>
        <div class="almostWithout-2"  v-if="showAlmostWithout">&nbsp;</div>
      </div>
      <img :src="iconUrl" />
      <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 100 100">
        <g>
          <title>{{title}}</title>
          <circle 
            cx="50" 
            cy="50" 
            r="50" 
            stroke="black" 
            stroke-width="1" 
            fill="none" 
            opacity="0.5" 
            v-if="outsideCircle"
            />
          <circle 
            cx="50" 
            cy="50" 
            r="32" 
            stroke="black" 
            stroke-width="1" 
            fill="none" 
            opacity="0.5" 
            v-if="insideCircle"
            />
          <path 
            id="thePathForTextTop" 
            fill="none" 
            stroke="none" 
            stroke-width="0" 
            opacity="undefined" 
            d="m15,50c0,-19.33702 15.66298,-35 35,-35c19.33702,0 35,15.66298 35,35" 
          />
          
          <path 
            id="thePathForTextBottom" 
            d="M 1.643 49.901 C 1.643 76.411 23.133 97.901 49.643 97.901 C 76.153 97.901 97.643 76.411 97.643 49.901"  
            opacity="undefined" 
            stroke="none" 
            fill="none"
          />
          <text id="txtTop" text-anchor="middle">
            <textPath 
              xlink:href="#thePathForTextTop" 
              startOffset="50%" 
              :style="textStyle"
            >
              {{topText}}
            </textPath> 
          </text> 
          <text id="txtBottom" text-anchor="middle">
            <textPath 
              xlink:href="#thePathForTextBottom" 
              startOffset="50%" 
              :style="textStyleBottom"
            >
            {{bottomText}}
            </textPath> 
          </text> 
        </g>
      </svg>
    </div>
  </div>
</template>
<script>
  // https://editor.method.ac/
  import { computed } from 'vue';

export default {
  name: "IconGeneric",
  props: {
    outsideCircle: {
      Type: Boolean,
      default: false,
    },
    insideCircle: {
      Type: Boolean,
      default: false,
    },
    showWithout: {
      Type: Boolean,
    },
    showAlmostWithout: {
      Type: Boolean,
    },
    iconUrl: {
      Type: String,
    },
    topText: {
      Type: String,
    },
    bottomText: {
      Type: String,
    },
    tooltip: {
      Type: String,
    },
    letterSpacing: {
      Type: String,
      default: "0.3em"
    },
    letterSpacingBottom: {
      Type: String,
      default: "0.4em"
    },
  },
  setup(props) {
    const textStyle = computed(() => {
      return `font-size: 16px; font-weight:600;letter-spacing: ${props.letterSpacing};`;
    });
    const textStyleBottom = computed(() => {
      return `font-size: 16px; font-weight:600;letter-spacing: ${props.letterSpacingBottom};`;
    });
    const title = computed(() => {
      return `${props.topText}${props.bottomText && props.bottomText != props.topText ? ' '+props.bottomText : ''}`;
    });
    const tooltipWithDefault = computed(() => {
      return props.tooltip ? props.tooltip : title.value;
    });

    return {
      textStyle,
      textStyleBottom,
      title,
      tooltipWithDefault,
    };
  },
};
</script>

<style lang="scss" >
  $myOrange: rgb(237, 90, 46);
  .custom-icon {
    display: inline-block;
    text-align: left;
    .logo-container {
      height: 70px;
      width: 70px;
      position: relative;
      margin: 10px;
      .without-container {
        position: absolute;
        transform: translateX(-50%) translateY(-50%);
        width: 60%;
        top: 50%;
        left: 50%;

        .without-1
        ,.without-2
        ,.almostWithout-1 
        ,.almostWithout-2
        {
          height: 2px;
          width:100%;
          transform: rotate(-35deg);
        }
        
        .without-2
        ,.almostWithout-2
        {
          transform: rotate(55deg);
        }
        .without-1 
        ,.without-2
        {          
          background-color: rgba(237, 90, 46, 0.8);
        }
        
        .almostWithout-1
        ,.almostWithout-2
         {
          height: 2px;
          border-color: rgba(237, 90, 46, 0.8);
          border-width: 1px;
          border-style: dashed;
        }
      }
      img {
        position: absolute;
        top:50%;
        left: 50%;
        height: 50%;
        width: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
      svg {
        
        position: absolute;
        top:0;
        left: 0;
        height: 100%;
        width: 100%;
        text-transform: uppercase;
        font-family: "Glacial Indifference", sans-serif;
        
      }
    }
  }
</style>