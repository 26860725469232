<template>
  <IconGeneric
    :iconUrl="require('../../assets/abricot.svg')"
    topText="Vegan"
    bottomText="Vegan"
  ></IconGeneric>
</template>
<script>
  
import IconGeneric from './IconGeneric.vue';

export default {
  name: "IconVegan",
  setup() {},
  components: {
    IconGeneric,
  },
};
</script>

<style lang="scss">
  
</style>