import { createI18n } from 'vue-i18n'
const messages = {
  'fr': {
    'foo': 'bar',
    "validations": {
      "required": "Le champs est obligatoire.",
      "email": "L'adresse email n'est pas valide",
      "minLength": "Le champs a une longueur minimale de {min} caractères."
    }
  },
};

const i18n = createI18n({
  locale: 'fr', // set locale
  fallbackLocale: 'fr', // set fallback locale
  messages, // set locale messages
});
export default {
  messages,
  i18n,
};