
export default function normalizeUrlParameter(str) {
  if(!str) {
    return '';
  }
  return str
    .replace(' ', '-')
    .replace("\n", '-')
    .replace(' ', '-')
    .replace('--', '-')
    ;
}
