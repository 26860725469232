<template>
  <GenericPage page-shortname="allergenes">

    <div class="allergenes-menu" v-if="true">
      <template v-if="!homeMenusAreLoading && menuAreReady">
        <Menu 
          v-for="menu in fullHomeMenus"
          :key="menu.id"
          :menu="menu"
          :displayAllergenes="true"
        ></Menu>
      </template>
      
    </div>

  </GenericPage>
  
</template>
<script>
  
import GenericPage from './GenericPage.vue';
import Menu from './Menu.vue';
import { storeToRefs } from 'pinia';
import { useFrontDataStore } from '../stores/front';
import { computed, watch } from 'vue';
import get from '../services/get';

export default {
  name: "Allergenes",
  components: {
    GenericPage,
    Menu,
  },
  setup() {
    const frontDataStore = useFrontDataStore();
    const { 
      homeMenus, 
      homeMenusAreLoading, 
      isMenuIsLoading, 
      menu: storeMenuCollection,
    } = storeToRefs(frontDataStore);   

    const homeMenuIds = computed(() => homeMenus.value.map(m => m.id));
    
    const fullHomeMenus = computed(() => {
      return homeMenuIds.value.map(mid => {
        return storeMenuCollection.value[mid] || {id: mid};
      });      
    });

    const menuAreReady = computed(() => 
    {
      return fullHomeMenus.value.every(hm => !isMenuIsLoading.value(hm.id));
    });
    //homeMenuId.value.length >= 1 && !homeMenuAreLoading.value(homeMenuIds.value));

    watch(fullHomeMenus, () => {
      fullHomeMenus.value.forEach((menu) => {
        if(!menu.id) {
          return;
        }
        if(!menu || !menu.forDate) {
          get.getMenu(menu.id);
        }
      });
    }, {deep: true, immediate: true});

    return {
      homeMenusAreLoading,
      //homeMenuIds,
      menuAreReady,
      fullHomeMenus,
    };
  },
};
</script>

<style lang="scss">
  .allergenes-menu {

  }
</style>