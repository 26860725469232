<template>
  <GenericPage page-shortname="howToOrder">
    <Button 
      label="Recevoir les prochains menus par email" 
      @click="subscribeToNewsletter" 
      class="std-button p-button-raised p-button-text howToOrderRegister">
    </Button>
    <iframe 
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2814.9035131865085!2d5.584005216723473!3d45.12829096381317!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478a92f680f5e995%3A0x3508f87f4bd8302b!2s64%20All.%20des%20Peupliers%2C%2038250%20Lans-en-Vercors!5e0!3m2!1sfr!2sfr!4v1663096173196!5m2!1sfr!2sfr" 
      width="600" 
      height="450" 
      style="margin-top: 20px; width: 100%;border:0;" 
      allowfullscreen="" 
      loading="lazy" 
      referrerpolicy="no-referrer-when-downgrade"></iframe>
  </GenericPage>
</template>
<script>
  
import GenericPage from './GenericPage.vue';
import { useFrontDataStore } from '../stores/front';

export default {
  name: "HowToOrder",
  components: {
    GenericPage,
  },
  setup() {
    const frontDataStore = useFrontDataStore();

    const subscribeToNewsletter = () => {
      frontDataStore.displayRegisterToNewsletterPopin();
    };

    return {
      subscribeToNewsletter,
    };
  },
};
</script>
<style>
  .howToOrderRegister {
    margin-top: 15px;
  }
</style>