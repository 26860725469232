import moment from 'moment';
import normalizeUrlParameter from './normalizeUrlParameter';

export default function normalizeMenu(menu, excludeDeleted = false) {
  if(!menu) {
    return {};
  }

  const x = new Date();
  const differenceFuseauxEnHeures = Math.abs(x.getTimezoneOffset() / 60);
  const canOrderUntill = differenceFuseauxEnHeures < 0 ? (typeof menu.canOrderUntil == 'object' ? menu.canOrderUntil : `${menu.canOrderUntil}`.replace('+00:00', '-0'+Math.abs(differenceFuseauxEnHeures)+':00')) : (typeof menu.canOrderUntil == 'object' ? menu.canOrderUntil : `${menu.canOrderUntil}`.replace('+00:00', '+0'+differenceFuseauxEnHeures+':00'));
  
  return {
    ...menu,
    menuDateForUrl: normalizeUrlParameter(menu.menuDate),
    forDate: moment(menu.forDate).toDate(),
    mailIntro: menu.mailIntro || '',
    canOrderUntil: moment(canOrderUntill).toDate(),
    mailCreatedSendDateString: menu.mailCreatedSendDate ? moment(menu.mailCreatedSendDate).calendar() : '',
    mailLastChanceSendDateString: menu.mailLastChanceSendDate ? moment(menu.mailLastChanceSendDate).calendar() : '',
    
    canOrder: (moment(canOrderUntill).toDate() > new Date()),
    userCanOrder: (moment(canOrderUntill).toDate() > new Date()),
    foodMenuItems: (menu.foodMenuItems || [])
      .filter(fmi => !fmi.deleted || !excludeDeleted)
      .sort((a, b) => (a.orderPosition > b.orderPosition) ? 1 : -1)
      .map(foodMenuItem => ({
        ...foodMenuItem,
        foodMenuItemOptions: (foodMenuItem.foodMenuItemOptions)
          .filter(fmio => !fmio.deleted || !excludeDeleted)
          .sort((a, b) => (a.orderPosition > b.orderPosition) ? 1 : -1)
          .map(fmio => {
             let pricePerDishInCts = 0;
             if(fmio.foodMenuItemOptionIngredientPrices) {
              fmio.foodMenuItemOptionIngredientPrices.forEach((fmiop) => {
                let ingredientAmount = fmiop.unitPriceInCts * fmiop.unitQuantity;
                pricePerDishInCts += ingredientAmount;
              });
             }
             
             return {
              ...fmio,
              initialMaximumQuantity: fmio.maximumQuantity,
              initialRemainingQuantity: fmio.remainingQuantity,
              soldQuantity: (fmio.maximumQuantity - fmio.remainingQuantity),
              pricePerDishInCts: pricePerDishInCts,

              foodMenuItemOptionDiscounts: (fmio.foodMenuItemOptionDiscounts)
                .filter(fmiod => !fmiod.deleted || !excludeDeleted)
                .sort((a, b) => (a.quantity < b.quantity) ? 1 : -1)
                .map(fmiod => {
                  return {
                    ...fmiod,
                    price: fmiod.priceInCts / 100,
                  };
                }),
            };
          }),
      })),
    foodMenuTakeAways: (menu.foodMenuTakeAways || [])
      .filter(fmi => !fmi.deleted || !excludeDeleted)
      .sort((a, b) => (a.orderPosition > b.orderPosition) ? 1 : -1),
    foodMenuPackagings: (menu.foodMenuPackagings || [])
      .filter(fmi => !fmi.deleted || !excludeDeleted)
      .sort((a, b) => (a.orderPosition > b.orderPosition) ? 1 : -1),
    foodMenuMediaObjectMenu: (menu.foodMenuMediaObjectMenu ? {...menu.foodMenuMediaObjectMenu, absoluteUri: `${process.env.VUE_APP_APIREMOTEROOTURL}${menu.foodMenuMediaObjectMenu.contentUrl}`} : null ),
  };
}
