<template>
  <FoodMenuPage :menuId="menuId" :hasSeveralMenus="false"></FoodMenuPage>
</template>

<script>
import FoodMenuPage from './FoodMenuPage.vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: "FoodMenuRoute",
  setup() {
    const route = useRoute();
    const menuId = computed(() => route.params.id );

    return {
      menuId,
    };
  },
  components: {
    FoodMenuPage,
  },
};
</script>