<template>
  <footer class="p-4">    
    <div class="footer-inner">      
      
      <router-link 
      :to="{name: 'partenaires'}"
      class="block lg:inline-block lg:mt-0 text-teal-lighter"
      v-if="partenairesLoaded"
      >
        Partenaires
      </router-link>

      <router-link 
      :to="{name: 'termsAndConditions'}"
      class="block lg:inline-block lg:mt-0 text-teal-lighter"
      >
        Conditions générales de vente
      </router-link>

      
      <router-link 
      :to="{name: 'legal'}"
      class="block lg:inline-block lg:mt-0 text-teal-lighter"
      >
        Mentions légales
      </router-link>
      
      <router-link 
      :to="{name: 'allergenes'}"
      class="block lg:inline-block lg:mt-0 text-teal-lighter">
        Allergènes
      </router-link>      
    </div>
  </footer>
</template>

<script>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useAdminDataStore } from '../stores/admin';
import { useFrontDataStore } from '../stores/front';

export default {
  name: "About",
  setup() {
    const adminDataStore = useAdminDataStore();
    const frontDataStore = useFrontDataStore();

    const { isAuthenticated } = storeToRefs(adminDataStore);

    const { pages } = storeToRefs(frontDataStore);
    const pagePartenaires = computed(() => pages.value['partenaires'] || {});

    const partenairesLoaded = computed(() => !!(pagePartenaires.value.description));

    return {
      isAuthenticated,
      partenairesLoaded,
    };
  }
};
</script>