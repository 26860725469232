<template>
  <GenericPage page-shortname="termsAndConditions"></GenericPage>
</template>
<script>
  
import GenericPage from './GenericPage.vue';

export default {
  name: "TermesAndConditions",
  components: {
    GenericPage,
  },
  setup() {},
};
</script>