<template>
  <Header></Header>
  <div class="sm-main-content flex antialiased text-gray-800">
      <router-view></router-view>
  </div>
  
  <Toast />
  <ConfirmDialog></ConfirmDialog>
  <Footer></Footer>
  <NewsletterPopin></NewsletterPopin>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import NewsletterPopin from "./components/NewsletterPopin.vue";
import ConfirmDialog from 'primevue/confirmdialog';
// import { useFrontDataStore } from './stores/front'
import { useAdminDataStore } from './stores/admin'
import get from './services/get';

export default {
  name: "App",
  components: {
    ConfirmDialog,
    Header,    
    Footer,
    NewsletterPopin,
},
  setup() {
    // const frontDataStore = useFrontDataStore();
    const adminDataStore = useAdminDataStore();

    get.getHomeMenus();
    get.listPages();
    
    const authToken = localStorage.getItem('authToken');
    const authTokenGeneratedAtTimestamp = parseInt(localStorage.getItem('authTokenGeneratedAtTimestamp'));
    if(authToken) {
      adminDataStore.setAuthToken(authToken, authTokenGeneratedAtTimestamp);
    }
  },
};

// contenu consigné +0
// emballage jetable + 1

</script>

<style lang="scss">
  @import './css/frontend.scss';
</style>
  