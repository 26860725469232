<template>
  <Dialog 
    v-model:visible="newsletterRegisterPopinDisplayed" 
    :footer="true"
    header="Recevoir les menus"
    class="registerToNewsletterDialog"
    > 
      <form @submit.prevent="registerSubmitted">        
        <div class="line">
          <p>
            Indiquez ici votre adresse mail pour recevoir les menus chaque semaine.
            <br />
            Votre adresse email ne sera pas transmise
            <br />
            Vous pouvez vous désabonner quand vous le souhaitez.
            <br />
            <br />
          </p>
        </div>
        <div class="line">
          <label for="newsletterRegisterEmail" :class="{'p-error':v$.email.$invalid && submitted}">Adresse email : </label>
          <span class="p-input-icon-left">
            <i class="pi pi-at" />
            <InputText 
              type="email" 
              id="newsletterRegisterEmail"
              v-model="formData.email" 
              required="required" 
              :class="{'p-invalid':v$.email.$invalid && submitted}"
              />
          </span>
          
          <span v-if="v$.email.$error && submitted">
              <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
              <small class="p-error">{{error.$message}}</small>
              </span>
          </span>
          <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">
            <template v-if="v$.email.required.$invalid">{{v$.email.required.$message.replace('Value', 'Email')}}</template>
            <template v-if="v$.email.email.$invalid">{{v$.email.email.$message.replace('Value', 'Email')}}</template>            
          </small>
          
          <Message severity="warn" v-if="registerWarning">{{registerWarning}}</Message>
          <Message severity="error" v-if="registerError">{{registerError}}</Message>
        </div>
      </form>
      <template #footer>
        <template v-if="!loading">
          <Button label="Annuler" icon="pi pi-arrow-left" class="p-button-text p-button-secondary" @click="newsletterRegisterPopinDisplayed=false"/>
          <Button label="S'inscrire" icon="pi pi-check" class="p-button-text p-button-success"  @click="registerSubmitted"/>
        </template>
        <ProgressSpinner v-if="loading"/>
      </template>
  </Dialog>
</template>
<script>
import { storeToRefs } from 'pinia';
import { ref, reactive, watch } from 'vue';
import { useToast } from "primevue/usetoast";
import { useFrontDataStore } from '../stores/front';
import { useAdminDataStore } from '../stores/admin';
import Dialog from 'primevue/dialog';
import Message from 'primevue/message';
import { email, required } from "../misc/i18n-validators";
import api from "../services/api";
import get from "../services/get";
import { useVuelidate } from "@vuelidate/core";



export default {
  name: "NewsletterPopin",
  setup() {
    const frontDataStore = useFrontDataStore();
    const adminDataStore = useAdminDataStore();
    const toast = useToast();
    const { newsletterRegisterPopinDisplayed } = storeToRefs(frontDataStore);

    const loading = ref(false);

    const formData = reactive({
      email: '',
    });
    
    const rules = {
      email: { required, email },
    };
    const submitted = ref(false);
    const registerWarning = ref('');
    const registerError = ref('');

    const v$ = useVuelidate(rules, formData);

    watch(newsletterRegisterPopinDisplayed, () => {
      formData.email = '';
      submitted.value = false;
      registerError.value = '';
      registerWarning.value = '';
      loading.value = false;
      setTimeout(() => {
        if(newsletterRegisterPopinDisplayed.value) {
          document.querySelector('#newsletterRegisterEmail').focus();
        }
      }, 100);
    });

    const registerSubmitted = async () => {
      submitted.value = true;
      const isFormValid = !v$.value.$invalid;
      if(!isFormValid) {
        return false;
      }
      loading.value = true;
      
      try {
        const registerResponse = await api.registerNewsletterRecipient(formData.email);
        if(registerResponse.error) {
          const msg = registerResponse.message || 'Erreur inconnue';
          registerError.value = msg;
        } else if(registerResponse.warning) {
          const msg = registerResponse.message;
          registerWarning.value = msg;
        } else if(! registerResponse.error && registerResponse.message) {
          const msg = registerResponse.message;
          toast.add({severity:'success', summary: msg, detail: '', life: 5000});
          frontDataStore.hideRegisterToNewsletterPopin();
          if(adminDataStore.isAuthenticated) {
            get.listAllNewsletterRecipients(true);
          }
        } else {
          const msg = 'Erreur inconnue';
          registerError.value = msg;
        }
      }catch(e) {
        const msg = 'Erreur inconnue';
        registerError.value = msg;
      }
      loading.value = false;
      return false;
    };
    return {
      formData,
      loading,
      newsletterRegisterPopinDisplayed,
      registerSubmitted,
      registerError,
      registerWarning,
      submitted,
      v$,
    }
  },
  components: {
    Dialog,
    Message,
  }
};
</script>

<style lang="scss">
  .registerToNewsletterDialog {
    form {
      .line {
        label {
          display: block;

        }
        span.p-input-icon-left {
          display: block;
        }
      }
    }
  }
</style>