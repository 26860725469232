<template>
  <div class="s-admin sm-admin-login sm-page-content">
    <h3 class="text-lg text-bold">Se connecter</h3>
    <ProgressSpinner v-if="loading"/>
    <form @submit="submit" method="POST" action="" v-show="!loading">
      <p>
        <label for="loginUsername">
          Email
        </label>
        <InputText v-model="username" id="loginUsername" @keyup="onKeyUp" />
      </p>
      <p>
        <label for="loginPassword">
          Mot de passe
        </label>
        <InputText type="password" v-model="password" id="loginPassword" @keyup="onKeyUp" />       
      </p>
      <br />
      <p>
        <Button label="Envoyer" @click="submit" />
      </p>
    </form>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { ref, onMounted  } from 'vue';
// import { storeToRefs } from 'pinia';
import { useAdminDataStore } from '../stores/admin'
import api from '../services/api'
import setMeta from '../misc/setMeta'
import { useToast } from "primevue/usetoast";

export default {
  name: "Admin",
  setup() {
    
    const toast = useToast();
    const router = useRouter();
    const username = ref('');
    if(localStorage.getItem('loginUsername')) {
      username.value = localStorage.getItem('loginUsername');
    }
    const loading = ref(false);
    const password = ref('');
    const adminDataStore = useAdminDataStore();

    setMeta({title: "Miam! - Authentification", canIndex: false});

    const submit = async () => {
      localStorage.setItem('loginUsername', username.value);
      loading.value = true;
      try {
        const loginResponse = await api.login(username.value, password.value);
        if(loginResponse.code == 401) {
          toast.add({severity:'error', summary: 'Login impossible', detail:'Login impossible', life: 3000});
        }
        if(loginResponse.token) {
          adminDataStore.setAuthToken(loginResponse.token);
          localStorage.setItem('authToken', loginResponse.token);
          localStorage.setItem('authTokenGeneratedAtTimestamp', (new Date().getTime() / 1000));

          setInterval(async () => {
            const loginResponse = await api.login(username.value, password.value);
            if(loginResponse.token) {
              adminDataStore.setAuthToken(loginResponse.token);
              localStorage.setItem('authToken', loginResponse.token);
              localStorage.setItem('authTokenGeneratedAtTimestamp', (new Date().getTime() / 1000));
            }
          }, 1000 * 60 * 20);// refresh token toutes les 20 minutes, histoire de pas perdre la sessions

          router.push({
            name: 'admin'
          });
        }else{
          toast.add({severity:'error', summary: 'Login impossible', detail:'Login impossible', life: 3000});
        }
      }
      catch(e) {
        toast.add({severity:'error', summary: 'Login impossible', detail:'Login impossible', life: 3000});
      }

      loading.value = false;
      
      return false;
    };

    const onKeyUp = (event) => {
      if (event.key === 'Enter' || event.keyCode === 13) {
        submit();
      }
    };
    onMounted(() => {
      if(!username.value) {
        document.querySelector('#loginUsername').focus();
      }else{
        document.querySelector('#loginPassword').focus();
      }
    })
    return {
      loading,
      onKeyUp,
      password,
      submit,
      username,
    };
  }
};
</script>
<style lang="scss">
  .sm-admin-login {
    form {
      p {
        display: flex;
        text-align: left;
        width: 100%;
        margin-top: 10px;
        label {
          flex: 1;
        }
        .p-inputtext {
          flex: 1;
        }
      }
    }
  }
</style>