<template>
  <IconGeneric
    :iconUrl="require('../../assets/meat.svg')"
    topText="Végé"
    bottomText="tarien"
    tooltip="Végétarien"
    :showWithout="true"
  ></IconGeneric>
</template>
<script>
  
import IconGeneric from './IconGeneric.vue';

export default {
  name: "IconVegetarien",
  setup() {},
  components: {
    IconGeneric,
  },
};
</script>

<style lang="scss">
  
</style>