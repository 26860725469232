import { createApp } from "vue";
import App from "./App.vue";
import { createRouter, createWebHistory }  from 'vue-router'

import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';

// import Tabpanel from 'primevue/tabpanel';
import Home from "./components/Home.vue";
import FoodMenuRoute from "./components/FoodMenuRoute.vue";
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import ConfirmDialog from 'primevue/confirmdialog';
import ProgressSpinner from 'primevue/progressspinner';
import Textarea from 'primevue/textarea';

import Button from 'primevue/button';

import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputSimpleNumber from './components/InputSimpleNumber';
import RadioButton from 'primevue/radiobutton';

import About from "./components/About.vue";
import HowToOrder from "./components/HowToOrder.vue";
import Allergenes from "./components/Allergenes.vue";
import TermsAndConditions from "./components/TermsAndConditions.vue";
import Legal from "./components/Legal.vue";
import Contact from "./components/Contact.vue";
import Tooltip from 'primevue/tooltip';
import i18n from './i18n';
import moment from 'moment';

moment.locale('fr');

const Admin = () => import('./components/admin/Admin.vue');
const AdminEditMenu = () => import('./components/admin/AdminEditMenu.vue');
const AdminEditPage = () => import('./components/admin/AdminEditPage.vue');
const AdminEditMailTemplate = () => import('./components/admin/AdminEditMailTemplate.vue');
const AdminShowMenuOrders = () => import('./components/admin/AdminShowMenuOrders.vue');
const AdminMenuMails = () => import('./components/admin/AdminMenuMails.vue');
const AdminNewsletterRecipients = () => import('./components/admin/AdminNewsletterRecipients.vue');
const AdminIngredients = () => import('./components/admin/AdminIngredients.vue');
const AdminAddEditRecipe = () => import('./components/admin/AdminAddEditRecipe.vue');
const AdminShoppingList = () => import('./components/admin/AdminShoppingList.vue');
const AdminFoodMenuClients = () => import('./components/admin/AdminFoodMenuClients.vue');
const AutoFillFoodMenuClient = () => import('./components/admin/AutoFillFoodMenuClient.vue');


const AllMenus = () => import('./components/AllMenus');
const NewsletterUnsubscribe = () => import('./components/NewsletterUnsubscribe');
const OrderStep2 = () => import('./components/OrderStep2.vue');
const OrderReceipt = () => import('./components/OrderReceipt.vue');
const UpdateOrder = () => import('./components/UpdateOrder.vue');
const CancelOrder = () => import('./components/CancelOrder.vue');
const OrderConfirmation = () => import('./components/OrderConfirmation.vue');
const Prestations = () => import('./components/Prestations.vue');
const Partenaires = () => import('./components/Partenaires.vue');
const OrderCancelConfirmation = () => import('./components/OrderCancelConfirmation.vue');
const Enquete = () => import('./components/Enquete.vue');
const Logo = () => import('./components/Logo.vue');
//const Test = () => import('./components/Test.vue');
//const TestIcons = () => import('./components/TestIcons.vue');
const TestLogo = () => import('./components/TestLogo.vue');
import Login from "./components/Login.vue";
import Logout from "./components/Logout.vue";

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
  { 
    path: '/', 
    name: 'home',
    component: Home 
  },{ 
    path: '/tous-les-menus', 
    name: 'allMenus',
    component: AllMenus 
  },
  { 
    // referencé dans le PHP
    path: '/plats-a-emporter/:id/:menuDate', 
    name: 'foodMenu',
    component: FoodMenuRoute 
  },
  { 
    path: '/finaliser-votre-commande/:id', 
    name: 'orderStep2',
    component: OrderStep2
  },
  { 
    path: '/merci-pour-votre-commande', 
    name: 'orderConfirmation',
    component: OrderConfirmation
  },
  { 
    path: '/votre-commande-a-ete-annulee', 
    name: 'orderCancelConfirmation',
    component: OrderCancelConfirmation
  },
  { 
    // attention, lien référencé dans symfony miam-api
    path: '/modifier-votre-commande/:id/:shortcode', 
    name: 'updateOrder',
    component: UpdateOrder
  },
  { 
    // attention, lien référencé dans symfony miam-api
    path: '/annuler-votre-commande/:id/:shortcode', 
    name: 'cancelOrder',
    component: CancelOrder
  },
  { 
    // attention, lien référencé dans symfony miam-api
    path: '/recu-pour-votre-commande/:id/:shortcode', 
    name: 'orderReceipt',
    component: OrderReceipt
  },
  { 
    // attention, lien référencé dans symfony miam-api
    path: '/partenaires', 
    name: 'partenaires',
    component: Partenaires
  },
  { 
    path: '/enquete', 
    name: 'enquete',
    component: Enquete
  },
  { 
    // attention, lien référencé dans symfony miam-api
    path: '/se-desabonner-de-la-newsletter', 
    name: 'newsletterUnsubscribe',
    component: NewsletterUnsubscribe
  },  
  { 
    // attention, lien référencé dans symfony miam-api
    path: '/se-desabonner-de-la-newsletter/:email', 
    name: 'newsletterUnsubscribeWithEmail',
    component: NewsletterUnsubscribe
  },  
  { 
    path: '/logo', 
    name: 'logo',
    component: Logo
  },  
  { 
    path: '/testlogo', 
    name: 'testlogo',
    component: TestLogo
  },  
  { 
    path: '/a-propos', 
    name: 'about',
    component: About,
   },
   { 
     path: '/comment-commander', 
     name: 'howToOrder',
     component: HowToOrder, 
   },
   { 
     path: '/prestations', 
     name: 'prestations',
     component: Prestations, 
   },
  { 
    path: '/me-contacter', 
    name: 'contact',
    component: Contact 
  },
  { 
    path: '/allergenes', 
    name: 'allergenes',
    component: Allergenes
  },
  { 
    path: '/conditions-generales-de-vente', 
    name: 'termsAndConditions',
    component: TermsAndConditions 
  },
  { 
    path: '/legal', 
    name: 'legal',
    component: Legal 
  },
  { 
    path: '/login', 
    name: 'login',
    component: Login 
  },
  { 
    path: '/logout', 
    name: 'logout',
    component: Logout 
  },
  { 
    path: '/admin', 
    name: 'admin',
    component: Admin 
  },
  { 
    path: '/admin/tab/:tab', 
    name: 'adminTab',
    component: Admin 
  },
  { 
    path: '/admin/edit-menu/:id', 
    name: 'adminEditMenu',
    component: AdminEditMenu 
  },
  {   
    path: '/admin/edit-page/:id', 
    name: 'adminEditPage',
    component: AdminEditPage 
  },
  {   
    path: '/admin/AutoFillFoodMenuClient', 
    name: 'AutoFillFoodMenuClient',
    component: AutoFillFoodMenuClient 
  },
  
  {   
    path: '/admin/show-menu-orders/:id', 
    name: 'adminShowMenuOrders',
    component: AdminShowMenuOrders 
  },
  {   
    path: '/admin/menu-mails/:id', 
    name: 'adminMenuMails',
    component: AdminMenuMails,
  },
  {   
    path: '/admin/newsletter/inscrits', 
    name: 'adminNewsletterRecipients',
    component: AdminNewsletterRecipients 
  },
  {   
    path: '/admin/clients', 
    name: 'adminFoodMenuClients',
    component: AdminFoodMenuClients 
  },  
  {   
    path: '/admin/ingredients', 
    name: 'adminIngredients',
    component: AdminIngredients 
  },
  
  {
    path: '/admin/modifier-recette/:id', 
    name: 'adminEditRecipe',
    component: AdminAddEditRecipe
  },
  
  {
    path: '/admin/creer-recette', 
    name: 'adminAddRecipe',
    component: AdminAddEditRecipe
  },
  { 
    path: '/admin/edit-mail-template/:id', 
    name: 'adminEditMailTemplate',
    component: AdminEditMailTemplate 
  },
  { 
    path: '/admin/liste-de-course', 
    name: 'adminShoppingList',
    component: AdminShoppingList 
  },
];
const pinia = createPinia();

// 2. Create i18n instance with options


// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  //history: createWebHashHistory(),
  history: createWebHistory(),
  routes, // short for `routes: routes`
});

const app = createApp(App);
app.use(i18n);
app.use(PrimeVue, {
  locale: {
    startsWith: 'Commence par',
    contains: 'Contient',
    notContains: 'Ne contient pas',
    endsWith: 'Termine par',
    equals: 'Egal à',
    notEquals: 'Différent de',
    noFilter: 'Aucun filtre',
    lt: 'Less than',
    lte: 'Less than or equal to',
    gt: 'Greater than',
    gte: 'Greater than or equal to',
    dateIs: 'Date is',
    dateIsNot: 'Date is not',
    dateBefore: 'Date is before',
    dateAfter: 'Date is after',
    clear: 'Clear',
    apply: 'Apply',
    matchAll: 'Match All',
    matchAny: 'Match Any',
    addRule: 'Add Rule',
    removeRule: 'Remove Rule',
    accept: 'Yes',
    reject: 'No',
    choose: 'Choose',
    upload: 'Upload',
    cancel: 'Annuler',
    dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
    dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    dayNamesMin: ["Di","Lu","Ma","Me","Je","Ve","Sa"],
    monthNames: ["Janvier","Fevrier","Mars","Avril","Mai","Juin","Juillet","Aout","Septembre","Octobre","Novembre","Décembre"],
    monthNamesShort: ["Jan", "Fev", "Mar", "Avr", "Mai", "Jui","Jui", "Aou", "Sep", "Oct", "Nov", "Dec"],
    chooseYear: 'Choose Year',
    chooseMonth: 'Choose Month',
    chooseDate: 'Choose Date',
    prevDecade: 'Previous Decade',
    nextDecade: 'Next Decade',
    prevYear: 'Previous Year',
    nextYear: 'Next Year',
    prevMonth: 'Previous Month',
    nextMonth: 'Next Month',
    prevHour: 'Previous Hour',
    nextHour: 'Next Hour',
    prevMinute: 'Previous Minute',
    nextMinute: 'Next Minute',
    prevSecond: 'Previous Second',
    nextSecond: 'Next Second',
    am: 'am',
    pm: 'pm',
    today: 'Aujourd\'hui',
    weekHeader: 'Wk',
    firstDayOfWeek: 1,
    dateFormat: 'mm/dd/yy',
    weak: 'Weak',
    medium: 'Medium',
    strong: 'Strong',
    passwordPrompt: 'Enter a password',
    emptyFilterMessage: 'No results found', // @deprecated Use 'emptySearchMessage' option instead.
    searchMessage: '{0} results are available',
    selectionMessage: '{0} items selected',
    emptySelectionMessage: 'No selected item',
    emptySearchMessage: 'No results found',
    emptyMessage: 'No available options',
    aria: {
        trueLabel: 'True',
        falseLabel: 'False',
        nullLabel: 'Not Selected',
        star: '1 star',
        stars: '{star} stars',
        selectAll: 'All items selected',
        unselectAll: 'All items unselected',
        close: 'Close',
        previous: 'Previous',
        next: 'Next'
    }
}
});

// app.component('Tabpanel', Tabpanel);
app.use(router);
app.use(pinia);
app.use(ToastService);

app.use(ConfirmationService);



app.component('Button', Button);
app.component('InputNumber', InputNumber);
app.component('InputSimpleNumber', InputSimpleNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('RadioButton', RadioButton);
app.component('Textarea', Textarea);
app.component('Toast', Toast);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ProgressSpinner', ProgressSpinner);

app.directive('tooltip', Tooltip);

app.mount("#app");
