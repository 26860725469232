<template>
  <div class="s-admin sm-admin-login sm-page-content">
    <form @submit="submit">
      <p>
        <label>
          Mot de password
          <InputText type="password" v-model="password" />
        </label>
      </p>
      <br />
      <p>
        <Button label="Envoyer" />
      </p>
    </form>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { useAdminDataStore } from '../stores/admin'

export default {
  name: "Admin",
  setup() {
    const adminDataStore = useAdminDataStore();
    const router = useRouter();

    localStorage.setItem('authToken', '');
    localStorage.setItem('authTokenGeneratedAtTimestamp', '');
    localStorage.setItem('loginUsername', '')
    
    adminDataStore.logout();
    
    router.push({
      name: 'home'
    });
    return {};
  }
};
</script>