<template>
  <GenericPage page-shortname="about"></GenericPage>
</template>
<script>
  
import GenericPage from './GenericPage.vue';

export default {
  name: "About",
  components: {
    GenericPage,
  },
  setup() {},
};
</script>