<template>
  <div 
    class="sm-home" 
    :class="{
      'isAuthenticated': isAuthenticated,
      'hasSeveralMenus': hasSeveralMenus,
      'hasOnlyOneMenu': !hasSeveralMenus,
      }"
    :style="smHomeStyle"
    >
    <template v-if="!menuIsReady">
      <ProgressSpinner  />
    </template>
    <template v-if="menuIsReady">
      <template v-if="menuCanOrder">
        <div class="make-order-button-container" v-if="!isInOrderMode">
          <Button 
            label="Passer commande" 
            @click="goToOrderMode" 
            class="p-button-raised p-button-text"
            ></Button>
        </div>
        <div class="confirm-or-cancel-order-button-container" v-if="isInOrderMode">
          <Button 
            label="Annuler" 
            @click="isInOrderMode = false" 
            class="p-button-raised p-button-text cancel"
            ></Button>
          <Button 
            label="Étape suivante" 
            @click="goToOrderNextStep" 
            class="p-button-raised p-button-text continue"
            v-tooltip.top="'Valider vos quantitées'" 
            ></Button>
        </div>
      </template>
      <template 
        v-if="!menuCanOrder"
      >
        <div class="cant-order-now">
          Désolé, il n'est plus possible de passer commande pour ce menu. 
          Rendez-vous dans quelques jours.
        </div>
        <div class="home-register-newsletter-top">
          <Button 
            label="Recevoir les prochains menus par email" 
            @click="subscribeToNewsletter" 
            class="std-button p-button-raised p-button-text">
          </Button>
        </div>
      </template>

      <div 
        class="sm-menu-container"
        :class="{
          'a-utiliser-pour-make-screenshot': !hasSeveralMenus,
          'ATTENTION-UTILISER-LA-PAGE-MENU-POUR-SCREEN': hasSeveralMenus,
        }"
      >
        <Menu 
          v-if="menu && !showInstaMode"  
          :menu="menu" 
          :inputOrder="orderFromStore"
          :orderMode="isInOrderMode" 
          :submitted="submitted"
          @orderUpdated="orderUpdated"
          ></Menu>
      </div>

      <template v-if="menuCanOrder">
        <div class="make-order-button-container" v-if="!isInOrderMode">
          <Button 
            label="Passer commande" 
            @click="goToOrderMode" 
            class="p-button-raised p-button-text"
            ></Button>
        </div>
        <div class="confirm-or-cancel-order-button-container" v-if="isInOrderMode">
          <Button 
            label="Annuler" 
            @click="isInOrderMode = false" 
            class="p-button-raised p-button-text cancel"
            ></Button>
          <Button 
            label="Étape suivante" 
            @click="goToOrderNextStep" 
            class="p-button-raised p-button-text continue"
            v-tooltip.top="'Valider vos quantitées'" 
            ></Button>
        </div>
      </template>
      <template 
        v-if="!menuCanOrder"
      >
        <div class="home-register-newsletter-bottom">
          <Button 
            label="Recevoir les prochains menus par email" 
            @click="subscribeToNewsletter" 
            class="std-button p-button-raised p-button-text">
          </Button>
        </div>
        <div class="cant-order-now">
          Désolé, il n'est plus possible de passer commande pour ce menu. 
          Rendez-vous dans quelques jours.
        </div>
      </template>

      <template v-if="isAdmin">
        <div class="make-order-button-container mt-4">
          <Button 
            @click="showInstaMode = !showInstaMode"
            :label="`${showInstaMode ? 'Revenir en mode normal' : 'Afficher en mode carré pour Insta'}`"
          />
          <template v-if="showInstaMode">
            &nbsp;
            <Button 
              @click="instaModeWithBorder = !instaModeWithBorder"
              :label="`${instaModeWithBorder ? 'Augmenter la taille du menu' : 'Réduire la taille du menu'}`"
            />
          </template>
        </div>


        <div 
        v-if="showInstaMode"
        class="sm-menu-container"
        :class="{
          'a-utiliser-pour-make-screenshot-insta': !hasSeveralMenus,
          'ATTENTION-UTILISER-LA-PAGE-MENU-POUR-SCREEN': hasSeveralMenus,
        }"
      >
        <Menu 
          v-if="menu"  
          :menu="menu" 
          :inputOrder="orderFromStore"
          :orderMode="isInOrderMode" 
          :submitted="submitted"
          @orderUpdated="orderUpdated"
          ></Menu>
      </div>
      </template>

    </template>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import Menu from './Menu.vue';
import { useFrontDataStore } from '../stores/front';
import { useAdminDataStore } from '../stores/admin';
import { ref, watch, computed } from 'vue';
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router';
import get from '../services/get';
import setMeta from '../misc/setMeta';

export default {
  name: "FoodMenuPage",
  props: {
    menuId: {
      required: true, 
    },
    hasSeveralMenus: {
      required: false,
    },
  },
  emits: ['loaded', 'ordering', 'notordering'],
  setup(props, context) {
    const toast = useToast();
    const router = useRouter();
    const frontDataStore = useFrontDataStore();
    const adminDataStore = useAdminDataStore();
    const { menu: storeMenuCollection, pages, isMenuIsLoading } = storeToRefs(frontDataStore);

    const { isAuthenticated } = storeToRefs(adminDataStore);
    
    const submitted = ref(false);
    const showInstaMode = ref(false);
    const instaModeWithBorder = ref(false);

    const menu = computed(() => {
      return storeMenuCollection.value[props.menuId] || {id: props.menuId};
    });

    const menuIsReady = computed(() => !isMenuIsLoading.value(props.menuId));
    watch(menuIsReady, () => {
      context.emit('loaded');
    }, {immediate: true});

    watch(menu, () => {
      if(!menu.value || !menu.value.forDate) {
        get.getMenu(props.menuId);
      }
    }, {deep: true, immediate: true});
    

    const menuCanOrder = computed(() => menu.value.canOrder || isAuthenticated.value);
    
    const isInOrderMode = ref(false);
    watch(isInOrderMode, () => {
      get.getMenu(props.menuId, true);
      if(isInOrderMode.value) {
        context.emit('ordering');
      }else{
        context.emit('notordering');
      }
    });

    
    // we don't want reactivity here
    const { order: orderFromStoreLocal } = frontDataStore;
    const order = ref({...orderFromStoreLocal});
    const orderFromStore = ref({...orderFromStoreLocal});

    watch(pages, function() {
      if(!menu.value.menuDate) {
        return;
      }
      //let metaTitle = pages.value?.home?.metaTitle;
      let metaDescription = pages.value?.home?.metaDescription;// TODO : menu
      const routeFoodMenuPath = router.resolve({
        name: 'foodMenu', 
        params: {
          id: menu.value.id,
          menuDate: menu.value.menuDateForUrl,
        }
      }).fullPath;
      const routeFoodMenu = `${document.location.protocol}//${document.location.host}${routeFoodMenuPath}`;
      setMeta({
        title: `MIAM! - ${menu.value.menuDate}`, 
        description: metaDescription, 
        canIndex: true, 
        ogUrl: routeFoodMenu
      });
    }, {deep: true, immediate: true});


    const blinkInputOrderCount = () => {
      document.querySelectorAll('.p-inputnumber input').forEach((elm) => {
        elm.classList.add('poping');
        setTimeout(() => { elm.classList.remove('poping');}, 1000);
      });
    };

    const goToOrderMode = () => {
      submitted.value = false;
      isInOrderMode.value = ! isInOrderMode.value;
      if(isInOrderMode.value) {
        setTimeout(() => {
          document.querySelector('.sm-menu-item').scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
          });
          blinkInputOrderCount();

        }, 100);
      }
    };

    const goToOrderNextStep = () => {
      submitted.value = true;
      let totalQuantity = 0;
      Object.keys(order.value.foodMenuOrderItems).forEach((foodMenuItemId) => {
        const quantity = order.value.foodMenuOrderItems[foodMenuItemId];
        totalQuantity += quantity;
      });
      if(totalQuantity == 0) {
        document.querySelector('.sm-menu-item').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
        blinkInputOrderCount();
        toast.add({severity:'error', summary: 'Panier vide', detail:'Veuillez selectionner vos quantités dans le menu', life: 5000});
        return false;
      }
      frontDataStore.setOrder(order.value);

      router.push({
        name: 'orderStep2',
        params: {
          id: menu.value.id
        },
      });
      return false;
    };

    const orderUpdated = (newOrder) => {
      order.value = newOrder;
    };

    const subscribeToNewsletter = () => {
      frontDataStore.displayRegisterToNewsletterPopin();
    };

    const smHomeStyle = computed(() => {
      return '';
      /*
      if(menu.value.cssMaxWidth) {
        return `max-width: min(${menu.value.cssMaxWidth}px,100vw - 260px)`;
      }
      return '';
      */
    });

    const adaptInstaWidth = (tryAgain) => {
      const insta = document.querySelector('.a-utiliser-pour-make-screenshot-insta');
      if(!insta) {
        if(tryAgain) {
          setTimeout(() => adaptInstaWidth(true), 1000);
        }
        return ;
      }
      
      if(instaModeWithBorder.value) {
        insta.querySelector('.sm-menu').style.minWidth = null;
        insta.style.minWidth = insta.querySelector('.sm-menu').offsetHeight+'px';
      }else{
        insta.querySelector('.sm-menu').style.minWidth = insta.querySelector('.sm-menu').offsetHeight+'px';
        insta.style.minWidth = null;
      }
      //insta.querySelector('.sm-menu').style.minWidth = insta.querySelector('.sm-menu').offsetHeight+'px';
      setTimeout(adaptInstaWidth, 1000);
    };

    if(isAuthenticated.value) {
      setTimeout(() => adaptInstaWidth(true), 1000);
    }
    watch(instaModeWithBorder, () => {
      adaptInstaWidth(true);
    });
    watch(showInstaMode, () => {
      adaptInstaWidth(true);
    });

    return {
      isAdmin: isAuthenticated,
      isAuthenticated,
      menuIsReady: menuIsReady,
      isInOrderMode,
      goToOrderMode,
      goToOrderNextStep,
      instaModeWithBorder,
      menu,
      menuCanOrder,
      orderFromStore,
      orderUpdated,
      showInstaMode,
      smHomeStyle,
      submitted,
      subscribeToNewsletter,
    };
  },
  components: {
    Menu,
  },
};
</script> 