<template>
  <GenericPage page-shortname="legal"></GenericPage>
</template>
<script>
  
import GenericPage from './GenericPage.vue';

export default {
  name: "Legal",
  components: {
    GenericPage,
  },
  setup() {},
};
</script>