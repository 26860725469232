
export default function setMeta(opts) {
  const { 
    title, 
    description, 
    canIndex, 
    ogUrl,
    canonicalUrl = ogUrl,
   } = opts;

  if(title) {
    document.title = title;
  } else {
    document.title = 'MIAM!';
  }
  if(description) {
    document.querySelector('meta[name="description"]').setAttribute('content', description); 
  }else{
    document.querySelector('meta[name="description"]').setAttribute('content', description); 
  }

  if(canIndex && process.env.VUE_APP_CAN_INDEX == "true") {
    document.querySelector('meta[name="robots"]').setAttribute('content', 'index,follow'); 
  }else{
    document.querySelector('meta[name="robots"]').setAttribute('content', 'none'); 
  }
  if(ogUrl) {
    document.querySelector('meta[property="og:url"]').setAttribute('content', ogUrl); 
  }else{
    document.querySelector('meta[property="og:url"]').setAttribute('content', `${document.location.protocol}//${document.location.host}${document.location.pathname}`); 
  }
  if(canonicalUrl) {
    document.querySelector('link[rel="canonical"]').setAttribute('href', canonicalUrl); 
  }else{
    document.querySelector('link[rel="canonical"]').setAttribute('href', `${document.location.protocol}//${document.location.host}${document.location.pathname}`); 
  }
}