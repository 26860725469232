<template>
  <IconGeneric
    :iconUrl="require('../../assets/lactose.svg')"
    topText="Sans"
    bottomText="lactose"
    :showWithout="true"
  ></IconGeneric>
</template>
<script>
  
import IconGeneric from './IconGeneric.vue';

export default {
  name: "IconSansLactose",
  setup() {},
  components: {
    IconGeneric,
  },
};
</script>

<style lang="scss">
  
</style>