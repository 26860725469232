import { useAdminDataStore } from '../stores/admin';


const api = {
  ApiBaseUrl: `${process.env.VUE_APP_APIROOTURL}`,
  login(username, password) {
    return fetch(`${this.ApiBaseUrl}api/login`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({username: username, password: password})
    })
    .then((response) => response.json())
    ;
  },
  
  getDiscountFor(email, phone) {
    return fetch(`${this.ApiBaseUrl}api/orderDiscount`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({email, phone})
    })
    .then((response) => response.json())
    ;
  },
  
  
  getOrder(orderId, shortCode) {
    return fetch(`${this.ApiBaseUrl}api/order?id=${orderId}&shortCode=${shortCode}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    ;
  },
  
  cancelOrder(orderId, shortCode, sendEmailRecap) {
    const suffix = !sendEmailRecap ? '&sendEmailRecap=false' : '';
    return fetch(`${this.ApiBaseUrl}api/order?id=${orderId}&shortCode=${shortCode}${suffix}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      return response;
    })
    ;
  },
  
  getMenu(id) {
    return fetch(`${this.ApiBaseUrl}api/food_menus/${id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    ;
  },
  getAdminMenu(id) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_menus/${id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      }
    })
    .then((response) => response.json())
    ;
  },
  
  listCustomers() {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/customers`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      }
    })
    .then((response) => response.json())
    ;
  },
  
  listMenus(filters) {
    return fetch(`${this.ApiBaseUrl}api/food_menus?${filters}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    ;
  },
  
  listAdminMenus(filters) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_menus?${filters}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      }
    })
    .then((response) => response.json())
    ;
  },
  
  listPages(filters) {
    return fetch(`${this.ApiBaseUrl}api/pages?${filters}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then((response) => response.json())
    ;
  },
  
  listMailTemplates(filters) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/mail_templates?${filters}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      }
    })
    .then((response) => response.json());
  },

  updateGeneric(modelName, id, toUpdateDatas) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/${modelName}/${id}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify(toUpdateDatas)
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },
  
  deleteGeneric(modelName, id) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/${modelName}/${id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      }
    })
    ;
  },
  
  createGeneric(modelName, toAddDatas) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/${modelName}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify(toAddDatas)
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },

  createMenu(toCreateDatas) {
    return this.createGeneric('food_menus', toCreateDatas);
  },
  updateMenu(id, toUpdateDatas) {
    return this.updateGeneric('food_menus', id, toUpdateDatas);
  },
  updateMenuItem(id, toUpdateDatas) {
    return this.updateGeneric('food_menu_items', id, toUpdateDatas);
  },
  deleteMenuItem(id) {
    return this.deleteGeneric('food_menu_items', id);
  },
  createMenuItem(toAddDatas) {
    return this.createGeneric('food_menu_items', toAddDatas);
  },
  
  updateFoodMenuClient(id, toUpdateDatas) {
    return this.updateGeneric('food_menu_clients', id, toUpdateDatas);
  },
  createFoodMenuClient(toCreateDatas) {
    return this.createGeneric('food_menu_clients', toCreateDatas);
  },
  
  updateMenuItemOption(id, toUpdateDatas) {
    return this.updateGeneric('food_menu_item_options', id, toUpdateDatas);
  },
  deleteMenuItemOption(id) {
    return this.deleteGeneric('food_menu_item_options', id);
  },
  createMenuItemOption(toAddDatas) {
    return this.createGeneric('food_menu_item_options', toAddDatas);
  },
  updateMenuItemOptionDiscount(id, toUpdateDatas) {
    return this.updateGeneric('food_menu_item_option_discounts', id, toUpdateDatas);
  },
  deleteMenuItemOptionDiscount(id) {
    return this.deleteGeneric('food_menu_item_option_discounts', id);
  },
  createMenuItemOptionDiscount(toAddDatas) {
    return this.createGeneric('food_menu_item_option_discounts', toAddDatas);
  },

  
  updateMenuTakeAway(id, toUpdateDatas) {
    return this.updateGeneric('food_menu_take_aways', id, toUpdateDatas);
  },
  createMenuTakeAway(toAddDatas) {
    return this.createGeneric('food_menu_take_aways', toAddDatas);
  },
  
  updateMenuPackaging(id, toUpdateDatas) {
    return this.updateGeneric('food_menu_packagings', id, toUpdateDatas);
  },
  createMenuPackaging(toAddDatas) {
    return this.createGeneric('food_menu_packagings', toAddDatas);
  },

  createOrder(toAddDatas) {
    return fetch(`${this.ApiBaseUrl}api/order`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(toAddDatas)
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },
  
  updatePage(id, toUpdateDatas) {
    return this.updateGeneric('pages', id, toUpdateDatas);
  },
  updateMailTemplate(id, toUpdateDatas) {
    return this.updateGeneric('mail_templates', id, toUpdateDatas);
  },
  

  registerNewsletterRecipient(email) {
    return fetch(`${this.ApiBaseUrl}api/newsletter/suscribe`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email: email})
    })
    .then((response) => response.json())
    ;
  },

  unregisterNewsletterRecipient(email) {
    return fetch(`${this.ApiBaseUrl}api/newsletter/unsuscribe`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email: email})
    })
    .then((response) => response.json())
    ;
  },

  testNewsletter(email, foodMenuId) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/newsletter/foodMenuSend`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({email: email, foodMenuId: foodMenuId})
    })
    .then((response) => response.json())
    ;
  },  

  testMessageNewsletter(email, message, subject, button) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/newsletter/message`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({email: email, message: message, subject: subject, button: button})
    })
    .then((response) => response.json())
    ;
  },  

  testNewsletterMultiMenu(email, foodMenuIds, subject, intro) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/newsletter/foodMenusSend`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({email, foodMenuIds, subject, intro})
    })
    .then((response) => response.json())
    ;
  }, 
  newsletterMultiMenu(foodMenuIds, subject, intro) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/newsletter/foodMenusSend`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({foodMenuIds, subject, intro})
    })
    .then((response) => response.json())
    ;
  },  
  messageNewsletter(message, subject, button) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/newsletter/message`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({message: message, subject: subject, button: button})
    })
    .then((response) => response.json())
    ;
  },  

  testNewsletterLastChance(email, foodMenuId) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/newsletter/foodMenuSendLastChance`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({email: email, foodMenuId: foodMenuId})
    })
    .then((response) => response.json())
    ;
  },  

  testNewsletterReady(email, foodMenuId, foodMenuTakeAwayId) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/newsletter/foodMenuSendReady`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({email: email, foodMenuId: foodMenuId, foodMenuTakeAwayId})
    })
    .then((response) => response.json())
    ;
  },
  
  sendNewsletter(foodMenuId) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/newsletter/foodMenuSend`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({foodMenuId: foodMenuId})
    })
    .then((response) => response.json())
    ;
  },
  
  sendNewsletterLastChance(foodMenuId) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/newsletter/foodMenuSendLastChance`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({foodMenuId: foodMenuId})
    })
    .then((response) => response.json())
    ;
  },
  
  sendNewsletterReady(foodMenuId, foodMenuTakeAwayId) {
    const adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/newsletter/foodMenuSendReady`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({foodMenuId: foodMenuId, foodMenuTakeAwayId: foodMenuTakeAwayId})
    })
    .then((response) => response.json())
    ;
  },
  
  listNewsletterRecipients(page = 1) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/newsletter_recipients?page=${page}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
    })
    .then((response) => response.json())
    ;
  },
  
  
  listFoodMenuClients(page = 1) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_menu_clients?page=${page}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
    })
    .then((response) => response.json())
    ;
  },
  
  
  listFoodMenuOrders(page = 1) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_menu_orders?page=${page}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
    })
    .then((response) => response.json())
    ;
  },
  listFoodMenuItems(page = 1) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_menu_items?page=${page}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
    })
    .then((response) => response.json())
    ;
  },
  listFoodIngredients(page = 1, filters="") {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_ingredients?page=${page}&${filters}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
    })
    .then((response) => response.json())
    ;
  },
  addIngredient(formData) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_ingredients`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify(formData)
    })
    .then((response) => response.json())
    ;
  },
  editIngredient(id, formData) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_ingredients/${id}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify(formData)
    })
    .then((response) => response.json())
    ;
  },

  
  listOrderDiscountRules(page = 1) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/order_discount_rules?page=${page}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
    })
    .then((response) => response.json())
    ;
  },
  addOrderDiscountRule(formData) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/order_discount_rules`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify(formData)
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },
  editOrderDiscountRule(id, formData) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/order_discount_rules/${id}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify(formData)
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },
  
  
  listFoodRecipes(page = 1, filters = "") {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_recipes?page=${page}&${filters}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
    })
    .then((response) => response.json())
    ;
  },
  addRecipe(formData) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_recipes`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify(formData)
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },
  editRecipe(id, formData) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_recipes/${id}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify(formData)
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },
  addRecipeIngredient(formData) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_recipe_ingredients`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify(formData)
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },
  editRecipeIngredient(id, formData) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_recipe_ingredients/${id}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify(formData)
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },
  
  addSubRecipe(formData) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_sub_recipes`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify(formData)
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },
  editSubRecipe(id, formData) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_sub_recipes/${id}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify(formData)
    })
    .then((response) => response.json())
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },
  
  deleteRecipeIngredient(id) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_recipe_ingredients/${id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
    })
    .then((response) =>  {
      if(!response.id && response.title && response.detail) {
        throw response;
      }
      if(!response.id && response.code && response.message) {
        throw response;
      }
      return response;
    })
    ;
  },
  
  deleteSubRecipe(id) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_sub_recipes/${id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
    })
    ;
  },
  
  getFoodRecipe(id) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/food_recipes/${id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      }
    })
    .then((response) => response.json())
    ;
  },
  
  uploadMediaObject(file) {
    const adminStore = useAdminDataStore();
    
    const data = new FormData();
    data.append('file', file);

    return fetch(`${this.ApiBaseUrl}api/media_objects`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: data,
    })
    .then((response) => response.json())
    ;
  },
  
  orderSetPayed(id, data) {
    var adminStore = useAdminDataStore();
    return fetch(`${this.ApiBaseUrl}api/orderSetPayed`, {
      method: 'POST',
      headers: {
        'Accept': 'application/ld+json',
        'Content-Type': 'application/json',
        'authorization': `Bearer ${adminStore.authToken}`,
      },
      body: JSON.stringify({
        food_menu_order_id: id,
        ...data
      }),
    })
    ;
  },
  contactForm(formData) {
    return fetch(`${this.ApiBaseUrl}api/contact`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
    .then((response) => response.json())
    ;
  },

};


export default api;