<template>
  <div class="sm-page-content" :class="[`sm-page-${pageShortname}`]" v-if="page">
    <h1 v-show="!hideTitle">{{page.title}}</h1>
    <article v-html="page.descriptionHtml"></article>
    <slot></slot>
  </div>
</template>
<script>
  
import { computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { useFrontDataStore } from '../stores/front';
import setMeta from '../misc/setMeta';


export default {
  name: "GenericPage",
  props: {
    pageShortname: {
      type: String,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    const frontDataStore = useFrontDataStore();
    const { pages } = storeToRefs(frontDataStore);
    const page = computed(() => pages.value[props.pageShortname] || {});    
    const router = useRouter();
    
    watch(page, function() {
      let metaTitle = page.value.metaTitle;
      let metaDescription = page.value.metaDescription;

      const pagePath = router.resolve({
        name: props.pageShortname, 
        params: {}
      }).fullPath;
      const routeFoodMenu = `${document.location.protocol}//${document.location.host}${pagePath}`;

      setMeta({
        title: metaTitle, 
        description: metaDescription, 
        canIndex: true,
        ogUrl: routeFoodMenu,
      });
    }, {deep: true, immediate: true});

    
    return {
      page: page,
    };
  },
};
</script>