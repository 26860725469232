<template>
  <IconGeneric
    :iconUrl="require('../../assets/sugar.png')"
    topText="Faible"
    bottomText="en sucre"
    :showAlmostWithout="true"
  ></IconGeneric>
</template>
<script>
  
import IconGeneric from './IconGeneric.vue';

export default {
  name: "IconFaibleSucre",
  setup() {},
  components: {
    IconGeneric,
  },
};
</script>

<style lang="scss">
  
</style>