<template>
  <GenericPage page-shortname="contact">
    <ProgressSpinner  v-if="loading"/>
    <Panel header="Me contacter" class="contactFormPanel" v-show="!loading">
      <form class="contactForm" @submit.prevent="contactFormSubmitted">         
        <div class="line">
          <label for="contactFromLastName" :class="{'p-error':v$.lastName.$invalid && submitted}">Votre nom : </label>          
          <InputText 
            type="email" 
            id="contactFromLastName"
            v-model="formData.lastName" 
            required="required" 
            :class="{'p-invalid':v$.lastName.$invalid && submitted}"
            />
          <span v-if="v$.lastName.$error && submitted">
              <span id="email-error" v-for="(error, index) of v$.lastName.$errors" :key="index">
              <small class="p-error">{{error.$message}}</small>
              </span>
          </span>
          <small v-else-if="(v$.lastName.$invalid && submitted) || v$.lastName.$pending.$response" class="p-error">
            <template v-if="v$.lastName.required.$invalid">{{v$.lastName.required.$message.replace('Value', 'Nom')}}</template>
          </small>
        </div>


        <div class="line">
          <label for="contactFromFirstName" :class="{'p-error':v$.firstName.$invalid && submitted}">Votre prénom : </label>          
          <InputText 
            type="email" 
            id="contactFromFirstName"
            v-model="formData.firstName" 
            required="required" 
            :class="{'p-invalid':v$.firstName.$invalid && submitted}"
            />
          <span v-if="v$.firstName.$error && submitted">
              <span id="email-error" v-for="(error, index) of v$.firstName.$errors" :key="index">
              <small class="p-error">{{error.$message}}</small>
              </span>
          </span>
          <small v-else-if="(v$.firstName.$invalid && submitted) || v$.firstName.$pending.$response" class="p-error">
            <template v-if="v$.firstName.required.$invalid">{{v$.firstName.required.$message.replace('Value', 'Nom')}}</template>
          </small>
        </div>
 
        <div class="line">
          <label for="contactFromEmail" :class="{'p-error':v$.email.$invalid && submitted}">Votre adresse email : </label>
          <span class="p-input-icon-left">
            <i class="pi pi-at" />
            <InputText 
              type="email" 
              id="contactFromEmail"
              v-model="formData.email" 
              required="required" 
              :class="{'p-invalid':v$.email.$invalid && submitted}"
              />
          </span>          
          <span v-if="v$.email.$error && submitted">
              <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
              <small class="p-error">{{error.$message}}</small>
              </span>
          </span>
          <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">
            <template v-if="v$.email.required.$invalid">{{v$.email.required.$message.replace('Value', 'Email')}}</template>
            <template v-if="v$.email.email.$invalid">{{v$.email.email.$message.replace('Value', 'Email')}}</template>            
          </small>
        </div>  
 
        <div class="line">
          <label for="contactFromPhone" :class="{'p-error':v$.phone.$invalid && submitted}">Votre téléphone : </label>
          <span class="p-input-icon-left">
            <i class="pi pi-tel" />
            <InputText 
              type="email" 
              id="contactFromPhone"
              v-model="formData.phone" 
              required="required" 
              :class="{'p-invalid':v$.phone.$invalid && submitted}"
              />
          </span>          
          <span v-if="v$.phone.$error && submitted">
              <span id="email-error" v-for="(error, index) of v$.phone.$errors" :key="index">
              <small class="p-error">{{error.$message}}</small>
              </span>
          </span>
          <small v-else-if="(v$.phone.$invalid && submitted) || v$.phone.$pending.$response" class="p-error">
            <template v-if="v$.phone.required.$invalid">{{v$.phone.required.$message.replace('Value', 'Téléphone')}}</template>
          </small>
        </div>  
        <div class="line forTextarea">
          <label for="contactMessage">Votre message : </label>
          <Textarea 
            id="contactMessage"
            v-model="formData.message" 
            required="required" 
            :class="{'p-invalid':v$.message.$invalid && submitted}"
            :autoResize="true" 
            cols="30" 
            rows="1"
            placeholder="Entrez ici votre message"
            ></Textarea>                 
          <span v-if="v$.message.$error && submitted">
              <span id="message-error" v-for="(error, index) of v$.message.$errors" :key="index">
              <small class="p-error">{{error.$message}}</small>
              </span>
          </span>
          <small v-else-if="(v$.message.$invalid && submitted) || v$.message.$pending.$response" class="p-error">
            <template v-if="v$.message.required.$invalid">{{v$.message.required.$message.replace('Value', 'Message')}}</template>
          </small>
        </div>
        
          
        <Message severity="error" v-if="contactError">{{contactError}}</Message>

        <div class="buttons-container">
          <Button label="Annuler" icon="pi pi-arrow-left" class="p-button-text p-button-secondary" @click="newsletterRegisterPopinDisplayed=false"/>
          <Button label="Envoyer le message" icon="pi pi-check" class="p-button-text p-button-success"  @click="contactFormSubmitted"/>
        </div>
      </form>
    </Panel>
  </GenericPage>
</template>
<script>
  
import { ref, reactive, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from "primevue/usetoast";
import Panel from 'primevue/panel';
import Textarea from 'primevue/textarea';
import Message from 'primevue/message';
import { email, required } from "../misc/i18n-validators";
import api from "../services/api";
import { useVuelidate } from "@vuelidate/core";
import GenericPage from './GenericPage.vue';

export default {
  name: "NewsletterUnsubscribe",
  setup() {
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();

    const formData = reactive({
      email: '',
    });
    const loading = ref(false);

    const emailFromRoute = computed(() => route.params.email);
    watch(emailFromRoute, () => {
      formData.email = emailFromRoute.value;
    });
    
    const rules = {
      email: { required, email },
      firstName: { required },
      lastName: { required },
      phone: { required },
      message: { required },
    };
    const submitted = ref(false);
    const contactError = ref('');

    const v$ = useVuelidate(rules, formData);

    const contactFormSubmitted = async () => {
      submitted.value = true;
      const isFormValid = !v$.value.$invalid;
      if(!isFormValid) {
        return false;
      }
      loading.value = true;
      contactError.value = '';
      
      try {
        const postData = {
          fromEmail: formData.email,
          fromFirstName: formData.firstName,
          fromLastName: formData.lastName,
          fromPhone: formData.phone,
          message: formData.message,
        };

        const contactResponse = await api.contactForm(postData);
        loading.value = false;
        if(contactResponse.error === false) {
          const msg = 'Votre message a bien été envoyé';
          toast.add({severity:'success', summary: msg, detail: '', life: 5000});
          router.push({name: 'home'});
        }else {
          const msg = 'Erreur inconnue';
          contactError.value = msg;
        }
      }catch(e) {
        const msg = 'Erreur inconnue';
        contactError.value = msg;
      }
      loading.value = false;
      return false;
    };
    
    return {
      formData,
      loading,
      contactFormSubmitted,
      contactError,
      submitted,
      v$,
    };
  },
  components: {
    GenericPage,
    Message,
    Panel,
    Textarea,
  }
};
</script>
<style lang="scss">
  .contactFormPanel {
    display: inline-block;
    .p-panel-content {
      background-color: rgba(255, 255, 255, 0.7);
    }
    margin-top: 20px;
  
    form {
      margin: auto;
      text-align: left;
      display: inline-block;
      display: flex;
      max-width: 70vw;
      flex-wrap: wrap;
      column-gap: 10px;;
      .line {
        margin-bottom: 10px;
        min-width: 30vw;
        flex-grow: 1;
        label {
          display: block;

        }
        span.p-input-icon-left {
          display: block;
          width: 100%;
        }
        input {
          width: 100%;
        }
        textarea {
          width: 100%;
          min-height: 300px;
        }
      }
      .line.forTextarea {
        min-width: 70vw;
        display: block;
      }
    }
  }
</style>